import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import feather from 'vue-icon'
import VueCountryCode from "vue-country-code";

Vue.use(VueCountryCode);

Vue.use(feather, 'v-icon')
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
