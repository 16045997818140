import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    bookingTime: null,
    bookingDate: null,
    service: null,
    serviceInfo: null,
    serviceCategory: null,
    serviceDuration: null
  },
  getters: {},
  mutations: {
    setTime(state, time) {
      state.bookingTime = time;
    },
    setDate(state, date) {
      state.bookingDate = date;
    },
    setService(state, service) {
      state.service = service.id;
      state.serviceInfo = service;
      state.serviceDuration = service.attributes.steps[0].duration
    },
    setServiceCategory(state, serviceCategory) { 
      state.serviceCategory = serviceCategory;
    }
  },
  actions: {},
  modules: {},
});
