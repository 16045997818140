<template>
  <div id="app">
    <div>
      <div class="top-nav">
        <svg @click="goHome" width="212" height="39" viewBox="0 0 212 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_177_438)">
            <path
              d="M31.2686 27.7407C27.1564 27.7407 25.5492 24.5175 25.4003 24.1978C26.8892 21.7454 30.1606 20.8783 33.051 20.1119C35.7968 19.3805 38.1704 18.7499 38.1704 16.915C38.1704 13.3765 34.9429 10.2715 31.2642 10.2715C28.5709 10.2715 26.2586 11.1386 24.5726 12.7721C22.7858 14.5063 21.8442 17.0069 21.8442 20.0111C21.8442 25.3759 25.7462 29.418 30.9182 29.418C36.572 29.418 38.2931 24.9292 38.3106 24.881L38.2099 24.8372C36.7734 27.6575 31.8291 27.7407 31.2686 27.7407ZM30.9226 20.0987C28.8818 20.8038 26.5739 21.6009 25.1112 23.681C24.9448 23.2387 24.2791 21.3031 24.2791 18.6973C24.2791 15.461 26.4031 10.6744 30.962 10.6744C32.3153 10.6744 35.8319 11.4977 35.8319 15.2683C35.8319 18.4039 33.5546 19.1878 30.9226 20.0987Z"
              fill="#2E2926"></path>
            <path
              d="M86.5141 28.1129C84.3682 28.1129 83.0851 26.5145 83.0851 23.8387V10.8583H86.4397V10.2452H83.0851V2.77402L83.0238 2.78279C83.0019 2.78717 80.8166 3.14627 80.8166 6.45706V10.2452H79.367V10.8583H80.8166V23.3439C80.8166 26.9787 82.5683 29.1465 85.5025 29.1465C89.0848 29.1465 90.1227 26.5189 90.1315 26.4882L90.3022 26.0328L90.0351 26.4357C90.022 26.4532 88.8965 28.1129 86.5141 28.1129Z"
              fill="#2E2926"></path>
            <path
              d="M10.8082 10.1795C6.57779 10.1795 4.91364 13.3808 4.89612 13.4115L4.65964 13.8757L4.98371 13.4684C5.00123 13.4465 6.90187 11.1211 9.928 11.1211C11.5746 11.1211 13.0636 11.8393 14.119 13.1443C15.3321 14.6464 15.9759 16.9018 15.9759 19.6696C15.9759 23.3088 14.4212 28.7261 10.1338 28.7261C9.35868 28.7261 8.6755 28.6122 8.05802 28.4195C7.54563 28.2487 7.02887 28.0166 6.53838 27.7101C4.56329 26.3962 3.76187 24.0796 3.76187 22.4198V0.974117L3.67428 1.0398C2.51376 1.92443 0.332837 3.58421 0.166422 3.70683L7.62939e-06 3.72873L0.162043 3.80756C0.166423 3.81194 0.170802 3.81193 0.192699 3.80317V3.81194C0.197078 3.81194 0.547426 3.77252 0.880257 4.06155C1.27878 4.4119 1.48899 5.10823 1.48899 6.07169V29.5143L3.3108 26.6897C3.67866 27.0663 6.07417 29.383 9.72655 29.383C12.5206 29.383 14.8066 28.2618 16.335 26.1466C17.64 24.3379 18.3582 21.8549 18.3582 19.1572C18.3626 15.0231 16.3832 10.1795 10.8082 10.1795Z"
              fill="#2E2926"></path>
            <path
              d="M56.209 27.802C54.7901 27.802 54.4923 26.4576 54.4923 25.3321V16.0829C54.4923 12.1152 52.7799 10.2715 49.1057 10.2715C47.3802 10.2715 45.5978 10.7839 44.2227 11.6729C42.9176 12.5181 42.1381 13.5954 42.1381 14.5545C42.1381 15.0187 42.2958 15.4128 42.5936 15.6931C42.8607 15.9427 43.2154 16.0785 43.5921 16.0785C44.2752 16.0785 45.0066 15.6055 45.0066 14.572C45.0066 14.2348 44.9497 14.0509 44.8884 13.8582C44.827 13.6567 44.7614 13.4509 44.7614 13.0348C44.7614 12.3035 45.6942 10.797 48.5714 10.797C50.5071 10.797 52.2719 12.6539 52.2719 14.6946C52.2719 16.1354 50.9362 17.5631 48.6108 18.6098C48.1028 18.8375 47.551 19.0565 47.0211 19.2711C44.2796 20.3659 41.4462 21.5001 41.4462 24.6927C41.4462 28.2312 44.6825 29.1728 46.5919 29.1728C50.1567 29.1728 51.7771 27.0663 51.9522 26.8298L52.2194 26.4576L51.8734 26.7554C51.8559 26.7685 50.1699 28.1874 47.8181 28.1874C45.0767 28.1874 44.1001 26.1072 44.1001 24.3248C44.1001 21.5177 46.154 20.506 48.3305 19.4375C49.8764 18.6754 51.4705 17.8915 52.2807 16.4069V25.6167C52.2807 28.0122 53.1347 29.1772 54.8908 29.1772C56.9403 29.1772 57.6629 27.3685 57.6673 27.3509L57.7593 27.1057L57.5753 27.2897C57.2425 27.6269 56.7783 27.802 56.209 27.802Z"
              fill="#2E2926"></path>
            <path
              d="M66.8727 28.2356C64.1356 28.2356 62.7474 26.4707 62.7474 22.9935V10.2102L62.6598 10.2802C61.5255 11.1692 59.3971 12.8378 59.2351 12.9604L59.0775 12.9867L59.2307 13.0611C59.2351 13.0655 59.2395 13.0655 59.2614 13.0568V13.0655C59.2658 13.0655 59.6205 13.0086 59.9489 13.2889C60.3387 13.6173 60.5314 14.2479 60.5314 15.1676V22.538C60.5314 28.0867 63.5313 29.2516 66.0494 29.2516C69.7105 29.2516 71.9177 26.2517 71.9352 26.2211L71.852 26.151C71.8389 26.1685 69.6142 28.2356 66.8727 28.2356Z"
              fill="#2E2926"></path>
            <path
              d="M76.1482 26.3349C75.8504 26.0809 75.4957 25.4766 75.4957 24.027V10.2102L75.4081 10.2802C74.2738 11.1692 72.1455 12.8378 71.9834 12.9604L71.8258 12.9867L71.979 13.0611C71.9834 13.0655 71.9922 13.0655 72.0097 13.0568V13.0655C72.0141 13.0655 72.3688 13.0086 72.6972 13.2889C73.087 13.6173 73.2841 14.2479 73.2841 15.1676V29.199L76.9408 26.5013L76.7175 26.5364C76.7087 26.5364 76.4372 26.5802 76.1482 26.3349Z"
              fill="#2E2926"></path>
            <path
              d="M103.103 10.2408L103.528 10.3503C103.545 10.3547 105.245 10.8058 105.245 12.5356C105.245 13.2319 104.872 14.029 104.307 15.2377C103.988 15.9208 103.624 16.696 103.234 17.6594C102.757 18.8287 102.166 20.563 101.483 22.5687C101.045 23.8431 100.598 25.1657 100.116 26.5101L93.9853 12.4611C93.9809 12.4568 93.6875 11.8174 93.9327 11.213C94.086 10.832 94.4232 10.543 94.9268 10.3503L95.2071 10.2452H88.66L89.0629 10.3547C89.076 10.3591 90.5869 10.7839 91.616 13.013L98.8726 29.6107C97.5851 32.5843 95.6932 35.7593 94.2349 35.7593C93.8232 35.7593 93.4992 35.6235 93.1532 35.4834C92.8028 35.3389 92.4437 35.19 91.9795 35.19C91.3708 35.19 90.7183 35.5622 90.7183 36.3724C90.7183 37.1125 91.1737 37.9753 92.4481 37.9753C93.7707 37.9753 95.2903 36.8016 96.8406 34.5769C98.2551 32.5492 99.5865 29.7903 100.589 26.8123C100.835 26.0809 101.075 25.3583 101.316 24.6401C103.751 17.3222 105.858 10.9985 109.374 10.3591L109.366 10.2496H103.103V10.2408Z"
              fill="#2E2926"></path>
            <path
              d="M124.15 18.6229C123.861 18.5178 123.581 18.4214 123.318 18.3207C120.485 17.2609 118.137 16.188 118.137 13.7093C118.137 11.4627 120.923 10.451 122.902 10.451C127.102 10.451 129.204 14.3881 129.204 14.8829H129.314V11.3751L129.278 11.3619C129.243 11.3488 125.788 10.0131 122.854 10.0131C119.223 10.0131 116.692 12.2028 116.692 15.3428C116.692 18.8156 120.568 20.2038 122.026 20.7294C122.123 20.7644 122.21 20.7951 122.285 20.8213C122.578 20.9308 122.88 21.0403 123.187 21.1498C125.771 22.0782 128.696 23.1293 128.696 25.4153C128.696 27.802 125.898 28.6516 123.274 28.6516C119.25 28.6516 117.292 25.5029 116.482 23.6241L116.377 23.3789V28.1261L116.412 28.1392C116.443 28.1524 119.6 29.3129 123.515 29.3129C128.455 29.3129 130.216 26.6415 130.216 24.1409C130.216 20.7819 126.708 19.5338 124.15 18.6229Z"
              fill="#2E2926"></path>
            <path
              d="M142.11 27.7363C137.998 27.7363 136.391 24.5131 136.242 24.1934C137.731 21.741 141.002 20.8739 143.892 20.1031C146.638 19.3718 149.012 18.7412 149.012 16.9106C149.012 13.3721 145.784 10.2671 142.106 10.2671C139.412 10.2671 137.1 11.1342 135.414 12.7677C133.627 14.5019 132.686 17.0026 132.686 20.0068C132.686 25.3715 136.588 29.4136 141.76 29.4136C147.413 29.4136 149.134 24.9248 149.152 24.8766L149.051 24.8328C147.615 27.6575 142.666 27.7363 142.11 27.7363ZM135.944 23.6767C135.777 23.2344 135.112 21.2987 135.112 18.693C135.112 15.4566 137.236 10.67 141.795 10.67C143.148 10.67 146.665 11.4933 146.665 15.2639C146.665 18.3996 144.392 19.1878 141.755 20.0987C139.719 20.7994 137.411 21.5965 135.944 23.6767Z"
              fill="#2E2926"></path>
            <path
              d="M191.238 27.7363C187.125 27.7363 185.518 24.5131 185.369 24.1934C186.858 21.741 190.13 20.8739 193.02 20.1031C195.766 19.3718 198.139 18.7412 198.139 16.9106C198.139 13.3721 194.912 10.2671 191.233 10.2671C188.54 10.2671 186.228 11.1342 184.541 12.7677C182.755 14.5019 181.813 17.0026 181.813 20.0068C181.813 25.3715 185.715 29.4136 190.887 29.4136C196.541 29.4136 198.262 24.9248 198.28 24.8766L198.179 24.8328C196.742 27.6575 191.794 27.7363 191.238 27.7363ZM190.887 20.0944C188.846 20.7994 186.538 21.5965 185.076 23.6767C184.909 23.2344 184.244 21.2987 184.244 18.693C184.244 15.4566 186.368 10.67 190.927 10.67C192.28 10.67 195.796 11.4933 195.796 15.2639C195.796 18.3996 193.524 19.1878 190.887 20.0944Z"
              fill="#2E2926"></path>
            <path
              d="M179.825 10.2496C177.556 10.2496 175.187 12.5181 174.158 13.8231V10.2014L170.51 12.7809L170.72 12.7589C170.725 12.7589 171.031 12.7283 171.329 12.9911C171.697 13.3195 171.89 13.9676 171.89 14.8654V17.6901C171.688 18.1018 171.465 18.5966 171.228 19.1178C170.93 19.7747 170.624 20.4491 170.339 21.0184C168.487 24.7058 166.166 28.1217 161.764 28.1217C154.722 28.1217 154.521 19.7966 154.521 18.8463C154.521 13.8669 157.981 10.7839 161.182 10.7839C163.959 10.7839 165.434 12.9035 165.434 14.4362C165.434 15.1457 165.636 15.6581 166.034 15.9515C166.35 16.1836 166.713 16.2186 166.897 16.2186C167.966 16.2186 168.128 15.115 168.128 14.4581C168.128 11.6072 164.3 10.324 161.581 10.324C159.141 10.324 156.868 11.143 155.174 12.6276C153.177 14.3793 152.121 16.9719 152.121 20.1163C152.121 22.9059 153.036 25.2795 154.762 26.9787C156.36 28.5509 158.55 29.4136 160.928 29.4136C166.985 29.4136 169.586 23.8956 171.881 19.0258C173.606 15.3647 175.1 12.1984 177.71 12.1984C178.332 12.1984 178.634 12.3648 178.927 12.5269C179.212 12.6801 179.501 12.8422 180.057 12.8422C181.165 12.8422 181.458 12.0188 181.458 11.5327C181.463 10.7007 180.618 10.2496 179.825 10.2496Z"
              fill="#2E2926"></path>
            <path
              d="M174.158 25.8444V15.6318L174.057 15.8157C173.514 16.7836 172.989 17.8959 172.433 19.0827L172.288 19.3849C172.161 19.6564 172.03 19.9367 171.894 20.2126L171.89 25.8401C171.89 28.7743 170.247 29.0195 170.23 29.0195L170.239 29.129H175.857H176.387L175.866 29.0195C175.849 29.0239 174.158 28.6385 174.158 25.8444Z"
              fill="#2E2926"></path>
            <path
              d="M211.352 26.4269C211.339 26.4444 210.213 28.0998 207.831 28.0998C205.685 28.0998 204.402 26.5014 204.402 23.8256V10.8452H207.756V10.2321H204.402V2.76089L204.341 2.76965C204.319 2.77403 202.133 3.13313 202.133 6.44392V10.2321H200.684V10.8452H202.133V23.3307C202.133 26.9656 203.885 29.1333 206.819 29.1333C210.402 29.1333 211.439 26.5057 211.448 26.4795L211.619 26.024L211.352 26.4269Z"
              fill="#2E2926"></path>
          </g>
          <defs>
            <clipPath id="clip0_177_438">
              <rect width="211.714" height="38" fill="white" transform="translate(0 0.5)"></rect>
            </clipPath>
          </defs>
        </svg>
      </div>
      <span class="progress-bar">
        <span :class="getClass"></span>
      </span>
      <p v-if="$route.name != 'Confirmation' && $route.path != '/form'" class="navigation">
        <span @click="$router.push('/')"
          :class="{ active: $route.path == '/' || $route.path == '/time-slots' || $route.path == '/contact-info' }">
          01 Behandlung </span>
        <span @click="$router.push(`/time-slots?service=${$store.state.service}`)"
          :class="{ active: $route.path == '/time-slots' || $route.path == '/contact-info' }">
          <v-icon class="chev-icon" name="play"></v-icon>
          02 Datum & Uhrzeit</span>
        <span :class="{ active: $route.path == '/contact-info' }" @click="$router.push('/contact-info')">
          <v-icon class="chev-icon" name="play"></v-icon>
          03 Kontaktangaben</span>
      </p>
      <div class="secondary-nav">
        <div v-if="$route.name != 'Confirmation' && $route.path != '/form'" class="page-name">
          <p>{{ $route.name }}</p>
        </div>
      </div>
      <div class="main-container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style>
@font-face {
  font-family: subTitleFont;
  src: url('./assets/Branch.ttf');
}

@font-face {
  font-family: Karla;
  src: url('./assets/Karla-VariableFont_wght.ttf');
}

#app {
  font-family: 'Karla';
  color: #2E2926;
  width: 100vw;
  padding: 0;
  margin: 0;
}

body {
  background-color: #e3dbd5;
  padding: 0;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  color: #2E2926;
}

.main-container {
  padding: 24px;
  padding-top: 12px;
}

p {
  font-family: "Karla" !important;
  font-style: normal;
}



/*Navbar*/
.top-nav {
  height: 37px;
  z-index: 1000;
  padding-left: 24px;
  padding-bottom: 16px;
  padding-top: 16px;
  background-color: #e3dbd5;
  position: sticky;
  top: 0;
}

.back-button {
  float: right;
  margin-right: 146px;
  width: 146px;
  height: 47px;
  background: transparent;
  border-width: 2px;
  border-style: outset;
  border-color: black;
  border-image: initial;
  color: black;
}

.secondary-nav div {
  padding-left: 24px;
  padding-top: 22.5px;
}


.page-name p {
  font-family: subTitleFont !important;
  font-weight: 400;
  line-height: 1.25;
  font-size: 32px;
  color: #2E2926;
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
  margin: 0;
}

/*
Page Indicator*/
.chev-icon {
  width: 40px;
  color: gray;
  fill: gray;
}

span .icon {
  width: 7px;
  margin-right: 5px;
  font-weight: bolder;
}

.navigation:first-child {
  margin: 0px;
}

.navigation span {
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: calc(100% / 3);
  font-size: 11px;
}

.navigation {
  padding-left: 24px;
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  background-color: #e3dbd5;
  margin: 0;
  font-weight: 700;

}

.navigation span {
  color: gray;
}

.active .chev-icon {
  color: black;
  fill: black;
}

.active {
  color: black !important;
}

/* Progress Bar */
.progress-bar {
  height: 1px;
  background-color: #e3dbd5;
  display: block;
  position: sticky;
  top: 69px;
  z-index: 1000;
}

.one {
  height: 1px;
  display: block;
  width: calc(100% / 4);
  background-color: black;
}

.two {
  height: 1px;
  display: block;
  width: calc(100% / 2);
  background-color: black;
}

.three {
  height: 1px;
  display: block;
  width: calc(100% / 4 * 3);
  background-color: black;
}

.four {
  width: 100%;
  background-color: black;
}

@media screen and (max-width: 600px) {
  .navigation {
    display: flex;
    justify-content: center;
    background-color: #F9F6F4;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 10px;
    position: sticky;
    top: 69px;
    z-index: 1000;
  }

  .navigation span {
    margin: 5px;
  }

  .progress-bar {
    top: 68px;
  }

  .back-button {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .page-name p {
    font-size: 42px;
  }

  .main-container {
    padding: 48px;
    padding-top: 24px;
  }

  .top-nav {
    padding-top: 16px;
    padding-left: 48px;
    padding-right: 48px;
  }


  .navigation {
    background-color: #e3dbd5;
    padding-left: 48px;
  }

  .secondary-nav div {
    padding-left: 48px;
  }

  .progress-bar {
    top: 64px;
  }
}
</style>
<script>
export default {
  created() {
    document.title = 'Jetzt buchen - beautysecret.ch'
  },
  methods: {
    goHome() {
      window.location.href = 'https://beautysecret.ch';
    },
  },
  computed: {
    getClass() {
      return this.$route.path == '/' ? 'one' : this.$route.path == '/time-slots' ? 'two' : this.$route.path == '/contact-info' ? 'three' : this.$route.path == '/confirmation' ? 'four' : 'four';
    }
  }
};
</script>
